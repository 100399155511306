import { makeStyles } from 'tss-react/mui'
import love from 'src/assets/images/articles/lovee.png'
const useStyles = makeStyles()((theme) => ({
  articleSliderContainer: {
    marginBottom: '100px',
  },
  mostPopularContainer: {
    padding: theme.spacing(0, 16),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
  },
  popularHeading: {
    fontFamily: 'Nunito Sans',
    fontWeight: 800,
    fontSize: '72px',
    lineHeight: '83px',
    textTransform: 'uppercase',
    position: 'relative',
    img: {
      transform: 'rotate(-112.37deg)',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '-10px',
      right: '-190px',
      width: '100px',
      height: '100px',
      backgroundImage: `url(${love?.src})`,
      transform: 'rotate(-12.37deg)',
      backgroundSize: 'cover',
      [theme.breakpoints.down('md')]: {
        display: 'none',
        top: '-40px',
        right: '-10px',
        width: '50px',
        height: '50px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '34px',
      lineHeight: '83px',
    },
  },
  recommendHeading: {
    fontFamily: 'Nunito Sans',
    fontWeight: 800,
    fontSize: '68px',
    lineHeight: '83px',
    textTransform: 'uppercase',

    [theme.breakpoints.down('sm')]: {
      fontSize: '34px',
      lineHeight: '83px',
    },
  },
  popularIcon: {
    cursor: 'pointer',
    marginLeft: '28px',
    [theme.breakpoints.down('sm')]: {
      width: '17.88px',
      height: '17.88px',
      '& *': {
        marginLeft: '-15px',
        width: '17.88px',
        height: '17.88px',
      },
    },
  },
  popularText: {
    ...theme.textPrimary,
    marginBottom: '20px',
  },
  linkContainer: {
    textDecoration: 'none',
  },
}))

export default useStyles
