import React, { CSSProperties, useState, useEffect } from 'react'
import {
  Card,
  CardActions,
  CardContent,
  Box,
  Typography,
  Grid,
} from '@mui/material'
import Image from 'next/image'
import useStyles from './Style'
import Tag from '../../atoms/Tag/Tag'
// import GreyHeart from 'src/assets/svgs/icons/greyHeart'
import HeartIcon from 'src/assets/images/articles/heart.svg'
import HeartLikeIcon from 'src/assets/images/articles/heart_like.svg'
import { tags } from 'src/types/components/shared/atom'
import { useRouter } from 'next/router'
import Moment from 'react-moment'
import isEmpty from 'lodash.isempty'

import {
  useDisLikeArticleMutation,
  useLikeArticleMutation,
  useUpdateArticlesMutation,
} from 'src/store/ApiCall/articleAPI'
import { v4 as uuid } from 'uuid'
import aa from 'search-insights'
import { getCookie } from 'cookies-next'
import DefaultImage from 'src/assets/images/default.jpg'
export interface ArticleCardProps {
  id: number
  img: string
  date: string
  title: string
  slug: string
  tags: tags[]
  liked: boolean
  style?: CSSProperties
  zenMode: boolean
  like_count: number
  comment_count: number
}

const ArticleCard: React.FC<ArticleCardProps> = ({
  id,
  img,
  date,
  title,
  tags,
  slug,
  style,
  liked,
  like_count,
  zenMode,
}) => {
  const { classes } = useStyles()
  useEffect(() => {
    setLike(liked)
  }, [id, liked])

  const [like, setLike] = useState<boolean>(liked)
  const [disLike] = useDisLikeArticleMutation()
  const [likeArticle] = useLikeArticleMutation()
  const [updateArticle] = useUpdateArticlesMutation()
  const [articleLikeCount, setArticleLikeCount] = useState<number>(like_count)
  const [runQuery, setRunQuery] = useState<boolean>(false)
  const anonymousUserToken = getCookie('anonymousUserToken')

  useEffect(() => {
    if (runQuery) {
      updateArticleHandler({
        data: {
          id: id,
          article_like_count: articleLikeCount,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleLikeCount])

  // let loggedInUser: number | undefined = undefined
  // const loggedInUserJSON = localStorage.getItem('dtl_token')
  // if (loggedInUserJSON) {
  //   loggedInUser = JSON.parse(loggedInUserJSON).id
  // }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [loggedInUser, setLoggedInUser] = React.useState<any>()
  aa('init', {
    appId: process.env.NEXT_PUBLIC_ALGOLIA_APP,
    apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
  })

  React.useEffect(() => {
    if (window) {
      const loggedInUserJSON = localStorage.getItem('dtl_token')

      if (loggedInUserJSON) {
        setLoggedInUser(JSON.parse(loggedInUserJSON).id)
      }
    }
  }, [])

  const router = useRouter()

  const handleClick = (slug: string, id: number) => {
    const uID = uuid()
    if (loggedInUser) {
      aa('clickedObjectIDs', {
        userToken: '' + loggedInUser,
        index:
          getCookie('lang') == 'En' ? 'search_articles' : 'mx_search_articles',
        eventName: 'Clicked_Article',
        objectIDs: [id + ''],
        headers: {
          'X-Algolia-Application-Id': process.env.NEXT_PUBLIC_ALGOLIA_APP + '',
          'X-Algolia-API-Key': process.env.NEXT_PUBLIC_ALGOLIA_API_KEY + '',
        },
      })

      /// viewedObjectIDs

      aa('viewedObjectIDs', {
        userToken: '' + loggedInUser,
        index:
          getCookie('lang') == 'En' ? 'search_articles' : 'mx_search_articles',
        eventName: 'Article_View',
        objectIDs: [id + ''],
        headers: {
          'X-Algolia-Application-Id': process.env.NEXT_PUBLIC_ALGOLIA_APP + '',
          'X-Algolia-API-Key': process.env.NEXT_PUBLIC_ALGOLIA_API_KEY + '',
        },
      })
    } else {
      aa('clickedObjectIDs', {
        userToken:
          anonymousUserToken && typeof anonymousUserToken === 'string'
            ? anonymousUserToken
            : uID,
        index:
          getCookie('lang') == 'En' ? 'search_articles' : 'mx_search_articles',
        eventName: 'Clicked_Article',
        objectIDs: [id + ''],
        headers: {
          'X-Algolia-Application-Id': process.env.NEXT_PUBLIC_ALGOLIA_APP + '',
          'X-Algolia-API-Key': process.env.NEXT_PUBLIC_ALGOLIA_API_KEY + '',
        },
      })

      /// viewedObjectIDs
      aa('viewedObjectIDs', {
        userToken:
          anonymousUserToken && typeof anonymousUserToken === 'string'
            ? anonymousUserToken
            : uID,
        index:
          getCookie('lang') == 'En' ? 'search_articles' : 'mx_search_articles',
        eventName: 'Article_View',
        objectIDs: [id + ''],
        headers: {
          'X-Algolia-Application-Id': process.env.NEXT_PUBLIC_ALGOLIA_APP + '',
          'X-Algolia-API-Key': process.env.NEXT_PUBLIC_ALGOLIA_API_KEY + '',
        },
      })
    }

    router.push(`/${slug}`)
  }

  const tagHandler = (e: React.SyntheticEvent, content = '') => {
    e.stopPropagation()
    if (!content || isEmpty(content)) return
    if (content.includes('#') && content.slice(1) === '#') {
      router.push(`/${content.slice(1).replace(/\s/g, '-')}-Support-Hub`)
      return
    }
    router.push(`/${content.replace(/\s/g, '-')}-Support-Hub`)
    return
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function disLikeHandler(data: any) {
    const response = await disLike(data).unwrap()
    return response
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function likeArticleHandler(data: any) {
    const response = await likeArticle(data).unwrap()
    if (response) {
      aa('clickedObjectIDs', {
        userToken: '' + loggedInUser.toString(), // aritcle id?
        index:
          getCookie('lang') == 'En' ? 'search_articles' : 'mx_search_articles',
        eventName: 'article_like',
        objectIDs: [id + ''],
        headers: {
          'X-Algolia-Application-Id': process.env.NEXT_PUBLIC_ALGOLIA_APP + '',
          'X-Algolia-API-Key': process.env.NEXT_PUBLIC_ALGOLIA_API_KEY + '',
        },
      })

      aa('convertedObjectIDs', {
        userToken: '' + loggedInUser.toString(),
        index:
          getCookie('lang') == 'En' ? 'search_articles' : 'mx_search_articles',
        eventName: 'article_like',
        objectIDs: [id + ''],
        headers: {
          'X-Algolia-Application-Id': process.env.NEXT_PUBLIC_ALGOLIA_APP + '',
          'X-Algolia-API-Key': process.env.NEXT_PUBLIC_ALGOLIA_API_KEY + '',
        },
      })
      return response
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function updateArticleHandler(data: any) {
    const response = await updateArticle(data).unwrap()
    return response
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const heartIconHandler = async (event: any) => {
    event.stopPropagation()
    if (!loggedInUser) {
      router.push(`/auth/login`)
      return
    }
    if (like) {
      // post request to delete record from article like where userId , articleId
      //after record setLike to false
      const response = await disLikeHandler({
        articleId: id,
        userId: loggedInUser,
      })

      if (response) {
        setRunQuery(true)
        setLike(false)
        const updatedCount = articleLikeCount - 1
        setArticleLikeCount(updatedCount)
      }
    } else {
      // add a post request to add record in article like tabble using userId ,article Id
      //after record setLike to true
      const response = await likeArticleHandler({
        data: {
          article: id,
          dtl_user: loggedInUser,
        },
      })
      if (response) {
        setRunQuery(true)
        setLike(true)
        const updatedCount = articleLikeCount + 1
        setArticleLikeCount(updatedCount)
      }
    }
  }

  useEffect(() => {
    if (liked) {
      setLike(liked)
    }
  }, [liked])
  return (
    <Card
      className={`${classes.cardContainer} ${
        zenMode && classes.zenModeCardContainer
      }`}
      sx={{ ...style }}
    >
      <span onClick={() => handleClick(slug, id)}>
        <Grid
          className={`${classes.cardImageContainer} ${
            zenMode && classes.zenModeImgContainer
          }`}
        >
          {/* <Image className={classes.cardImage} src={img} alt={title} fill /> */}
          <Image
            className={classes.cardImage}
            src={img ? img : DefaultImage?.src}
            alt={title}
            fill
          />
        </Grid>

        <CardContent className={classes.cardContentContainer}>
          <Box className={classes.cardDateContainer}>
            <span className={classes.cardDate}>
              <Moment format="MMMM DD, YYYY">{date}</Moment>
            </span>
            <span className={classes.cardHeartIcon} onClick={heartIconHandler}>
              {like ? (
                <Image src={HeartLikeIcon} alt="heartIcon" />
              ) : (
                <Image src={HeartIcon} alt="heartLike" />
              )}
              {/* <GreyHeart fill={like ? 'black' : 'none'} /> */}
            </span>
          </Box>
          <Typography className={classes.cardText}>{title}</Typography>
        </CardContent>
      </span>
      <CardActions className={classes.cardBtnContainer}>
        {tags
          ? tags.map((el, index) => (
              <Tag
                onClickHandler={true}
                key={index}
                content={el.content}
                id={el.id}
                color="black"
                background="transparent"
                onClick={(e) => tagHandler(e, el.content)}
              />
            ))
          : ''}
      </CardActions>
    </Card>
  )
}

export default ArticleCard
