/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import Link from 'next/link'
import { Box, Typography, Grid, MenuItem, Menu } from '@mui/material'
import { FillDownArrow } from 'src/assets/svgs'
import useStyles from './Style'
import { Nunito_Sans } from '@next/font/google'
import { tags } from 'src/types/components/shared/atom'
import { ButtonComponent } from '../../atoms'
import PartialSlider from './partialSlider/PartialSlider'
import {
  useGetAlgoliaRecommendedArticlesQuery,
  useGetPopularArticlesQuery,
  useLazyGetLazyFavouriteArticleIdByUserQuery,
} from 'src/store/ApiCall/articleAPI'

import isEmpty from 'lodash.isempty'
import { AlgoliaRecommendArticle, HeadingTextButton } from 'src/types/store'
import { recommendArticleInterface } from 'src/components/pages/recommendMeSignUp/recommendMe'

import { getCookie } from 'cookies-next'
import { preprocessAlgoliaRecommendedArticles } from 'src/utils'

const nunito_sans = Nunito_Sans({
  weight: ['400', '700', '800'],
  subsets: ['latin'],
})

export interface carouselType {
  id: number
  img: string
  date: string
  title: string
  slug: string
  tags: tags[]
  liked: boolean
  like_count: number
  comment_count: number
}

interface PropsType {
  zenMode: boolean
  headerContent: HeadingTextButton
  recommend?: boolean
  recommendArticleData?: recommendArticleInterface[] | []
}

const ArticleSlider: React.FC<PropsType> = ({
  zenMode,
  headerContent,
  recommend,
  recommendArticleData,
}: PropsType) => {
  const [carouselData, setCarouselData] = useState<carouselType[]>()
  const [dropDownTitle, setDropDownTitle] = useState<string>(
    headerContent?.headingLogo ? headerContent.headingLogo[0].heading : ''
  )
  const [getFavArticleId, { data: favArticleId }] =
    useLazyGetLazyFavouriteArticleIdByUserQuery()
  const lang = getCookie('lang')
  const [, setIndexName] = React.useState('search_articles')

  React.useEffect(() => {
    if (lang != 'En') {
      setIndexName('mx_search_articles')
    } else {
      setIndexName('search_articles')
    }
  }, [lang])

  const [loggedInUser, setLoggedInUser] = React.useState(-1)

  const [recommendations, setRecommendations] = useState<
    AlgoliaRecommendArticle[]
  >([])
  const { data: algoliaRecommendedArticle } =
    useGetAlgoliaRecommendedArticlesQuery()

  React.useEffect(() => {
    if (algoliaRecommendedArticle?.data?.length) {
      const recommendedArticle = preprocessAlgoliaRecommendedArticles(
        algoliaRecommendedArticle
      )
      setRecommendations(recommendedArticle)
    }
  }, [algoliaRecommendedArticle])
  React.useEffect(() => {
    if (window) {
      const loggedInUserJSON = localStorage.getItem('dtl_token')

      if (loggedInUserJSON) {
        setLoggedInUser(JSON.parse(loggedInUserJSON).id)
      }
    }
  }, [])

  React.useEffect(() => {
    if (loggedInUser) {
      getFavArticleId(loggedInUser)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser])

  const { data } = useGetPopularArticlesQuery({ dropDownTitle, loggedInUser })

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (name: string) => {
    if (typeof name == 'string') {
      setDropDownTitle(name)
    }
    setAnchorEl(null)
  }
  React.useEffect(() => {
    if (recommend && recommendArticleData && recommendArticleData.length) {
      setCarouselData(recommendArticleData)
      return
    }

    if (
      dropDownTitle === 'Most Popular' &&
      recommendations &&
      recommendations.length
    ) {
      const recommendedArticle = recommendations.map((curr: any) => {
        return {
          id: curr.id,
          img: curr.image ? curr.image : '',
          title: curr.title,
          date: curr.pubblishedAt,
          tags:
            curr.tags && curr.tags.length
              ? curr.tags.map((el: string, i: number) => {
                  return { content: el, id: i }
                })
              : [],
          slug: curr.slug,
          liked:
            favArticleId &&
            favArticleId.length &&
            favArticleId.find((el) => el == curr.id)
              ? true
              : false,

          like_count: curr.likes_count,
          comment_count: curr.comments_count ?? 0,
        }
      })
      setCarouselData(recommendedArticle)
      return
    }

    if (data && data.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const articleData: any =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data.map((curr: any) => {
          const {
            images,
            title,
            publishedAt: pAt,
            tags,
            slug,
            isLiked,
            like_count,
            comment_count,
          } = curr
          const tagsArr =
            tags &&
            tags.length &&
            tags.map((curr: { id: number; topic: string }) => {
              return { content: curr.topic, id: curr.id }
            })

          return {
            id: curr.id,
            img: images ? (images.url ? images.url : '') : '',
            title: title,
            date: pAt,
            tags: tagsArr,
            slug: slug,
            liked: isLiked,
            like_count,
            comment_count,
          }
        })

      setCarouselData(articleData)
      return
    }

    setCarouselData([])
  }, [
    data,
    recommend,
    recommendArticleData,
    dropDownTitle,
    favArticleId,
    recommendations,
  ])
  const { classes } = useStyles()
  return (
    <Box className={classes.articleSliderContainer}>
      {!isEmpty(carouselData) ? (
        <Box>
          <Grid
            container
            alignItems="center"
            className={classes.mostPopularContainer}
          >
            <Grid
              container
              alignItems="center"
              item
              md={9}
              sm={12}
              sx={{ mb: '24px' }}
            >
              <Grid item>
                <Typography
                  className={`${
                    !recommend
                      ? classes.popularHeading
                      : classes.recommendHeading
                  } ${nunito_sans.className}`}
                >
                  {dropDownTitle}
                </Typography>
              </Grid>
              {!recommend && (
                <Grid>
                  <span
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    className={classes.popularIcon}
                  >
                    <FillDownArrow />
                  </span>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    {headerContent.headingLogo?.map((curr, i) => (
                      <MenuItem
                        key={i}
                        onClick={() => handleClose(curr.heading)}
                      >
                        {curr.heading}
                      </MenuItem>
                    ))}
                    {/*                  
                  <MenuItem onClick={() => handleClose('Most Commented')}>
                    Most Commented
                  </MenuItem>
                  <MenuItem onClick={() => handleClose('Newest')}>
                    Newest
                  </MenuItem>
                  <MenuItem onClick={() => handleClose('Oldest')}>
                    Oldest
                  </MenuItem> */}
                  </Menu>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              item
              md={3}
              sx={{
                display: { sm: 'none', xs: 'none', md: 'block' },
              }}
            >
              <Grid item sx={{ marginLeft: 'auto', width: 'fit-content' }}>
                <Link
                  href={
                    headerContent.button.href ? headerContent.button.href : '#'
                  }
                  className={classes.linkContainer}
                >
                  <ButtonComponent
                    type="text"
                    text={headerContent.button.content}
                  />
                </Link>
              </Grid>
            </Grid>
            <Grid
              item
              md={6}
              sx={{ display: { sm: 'none', xs: 'none', md: 'block' } }}
            >
              <Typography className={classes.popularText}>
                {`${headerContent.description}`}
              </Typography>
            </Grid>
          </Grid>
          <Box>
            {carouselData && !isEmpty(carouselData) ? (
              <PartialSlider zenMode={zenMode} content={carouselData} />
            ) : null}
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

export default React.memo(ArticleSlider)
