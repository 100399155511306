import { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
// import dynamic from 'next/dynamic'
import prevBtn from 'src/assets/images/aboutUs/prevBtn.svg'
import nextBtn from 'src/assets/images/aboutUs/nextBtn.svg'
import ArticleCard from '../../articleCard/ArticleCard'
import { carouselType } from '../ArticleSlider'
import Slider from 'react-slick'

// const Slider = dynamic(() => import('react-slick').then((m) => m.default), {
//   ssr: true,
// })

type SlideProps = {
  zenMode: boolean
  content: carouselType
}

const Slide = ({ zenMode, content }: SlideProps) => {
  return (
    <Grid
      sx={{
        width: '100%',
      }}
      alignContent="center"
      justifyContent="center"
    >
      <Grid
        sx={{
          marginLeft: '1px',
          marginRight: '1px',
        }}
      >
        <ArticleCard
          zenMode={zenMode}
          id={content.id}
          img={content.img}
          date={content.date}
          title={content.title}
          tags={content.tags}
          slug={content.slug}
          liked={content.liked}
          like_count={content.like_count}
          comment_count={content.comment_count}
        />
      </Grid>
    </Grid>
  )
}

interface IProps {
  zenMode: boolean
  content: carouselType[]
}

const PartialSlider: React.FC<IProps> = ({ zenMode, content }) => {
  const [slideCount, setSlideCount] = useState(3)
  useEffect(() => {
    if (
      typeof window === 'object' &&
      window.innerWidth > 1024 &&
      window.innerWidth < 3000
    ) {
      setSlideCount(3)
    } else if (
      typeof window === 'object' &&
      window.innerWidth > 680 &&
      window.innerWidth < 1024
    ) {
      setSlideCount(2)
    } else if (
      typeof window === 'object' &&
      window.innerWidth > 0 &&
      window.innerWidth < 680
    ) {
      setSlideCount(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeof window === 'object' ? window.innerWidth : null])

  const slickSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    slidesToShow: slideCount,
    centerMode: true,
  }
  return (
    <div className="App">
      <Box m="20">
        <Box
          sx={{
            position: 'relative',

            '& .slick-arrow': {
              backgroundColor: 'white',
              position: 'absolute',
              color: 'black',
              width: '38px',
              height: '38px',
              zIndex: 100,
              borderRadius: '50%',
              border: zenMode ? '1px solid black' : 'none',
              transition: '0.2s',
              '&::before': {
                transition: '0.2s',
              },
            },
            '& .slick-prev': {
              left: '50px',
              top: '130px',
              background: `url(${prevBtn?.src})`,
              '&::before': {
                content: '""',
                width: '38px',
                height: '38px',
              },
              '&:hover': {
                background: `url(${prevBtn?.src})`,
              },
              '@media (max-width:750px)': {
                left: '40px',
              },
            },
            '& .slick-next': {
              background: `url(${nextBtn?.src})`,
              right: '66px',
              top: '130px',
              '&::before': {
                content: '""',
                width: '38px',
                height: '38px',
              },
              '&:hover': {
                background: `url(${nextBtn?.src})`,
              },
              '@media (max-width:750px)': {
                right: '30px',
              },
            },
          }}
        >
          <Slider {...slickSettings}>
            {content && content.length
              ? content.map((curr, index) => (
                  <Slide key={index} zenMode={zenMode} content={curr} />
                ))
              : ''}
            {content && content.length
              ? content.map((curr, index) => (
                  <Slide key={index} zenMode={zenMode} content={curr} />
                ))
              : ''}
          </Slider>
        </Box>
      </Box>
    </div>
  )
}

export default PartialSlider
